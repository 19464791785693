<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { gql } from '@olyslager/api-client'
import { languages } from '@olyslager/global-utilities'
import { OlyTextBox, translator, useData } from '@olyslager/shared-components'

import { SearchResult } from '@/types'

import useSubmitSearch from '@/composables/submitSearch'

import debounce from './debounce'

import InstantSearchResult from './InstantSearchResult.vue'

const props = defineProps({
  maxSearchResults: {
    type: Number,
    required: false,
    default: 10
  },
  minInput: {
    type: Number,
    required: false,
    default: 3
  }
})

const { t } = translator.useI18n()
const searchResults = ref<SearchResult[]>()

const { loading, loadingSlow, errors, results, execute } = useData()
const route = useRoute()

const { submitSearch } = useSubmitSearch()
const searchIcon = import.meta.env.VITE_NETLIFY_CDN_URL + '/global-icons/search.svg'

const searchInput = ref<string>()
const showResult = ref(true)

// call search api with timeout for instant result
function validateInput () {
  const inputLength = searchInput.value?.length
  if (inputLength as number >= props.minInput) {
    return true
  } else if (inputLength === 0) {
    // reset the results so the results are hidden when you clear the input.
    searchResults.value = undefined
    results.value = undefined
    errors.value = undefined
  }
  return false
}

const onInput = debounce(() => {
  const validated = validateInput()
  if (validated && route.params.locale) {
    callSearchAPI()
  }
}, 500)

const callSearchAPI = () => {
  execute(gql`
    query getSearchResults($searchInput: SearchInput!) {
      search(searchInput: $searchInput) {
        results {
          vehicleId
          category
          make
          model
          type
          yearEnd
          yearStart
          searchHighlights {
            key
            values {
              startIndex
              endIndex
            }
          }
        }
      }
    }
  `,
  {
    searchInput: {
      searchText: searchInput.value,
      language: languages.getLanguageByLocale(route.params.locale as string, true)?.code2,
      facetCount: 0,
      searchCount: props.maxSearchResults
    }
  }).then(() => {
    if (results.value) {
      searchResults.value = results.value.search.results
    }
  })
}
</script>

<template>
  <div
    v-click-outside="() => showResult = false"
    class="instant-search"
  >
    <OlyTextBox
      v-model="searchInput"
      :icon-url="searchIcon"
      :placeholder-text="t('searchinput.search-placeholder')"
      :loading="loading || loadingSlow"
      @submit="submitSearch"
      @input="onInput"
      @click="showResult = true"
    />
    <InstantSearchResult
      v-if="(searchResults || errors) && showResult"
      :search-input="searchInput"
      :has-errors="errors !== undefined"
      :results="searchResults"
      @search-button-click="submitSearch(searchInput)"
    />
  </div>
</template>

<style scoped lang="scss">
  .instant-search {
    position: relative;
  }
</style>
