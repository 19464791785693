const debounce = (func: (args?: unknown) => void, delay: number) => {
  let timeout

  return (...args: unknown[]) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      func(...args)
    }, delay)
  }
}
export default debounce
