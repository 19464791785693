import { useRouter } from 'vue-router'

export default function useSubmitSearch (): { submitSearch: (value: string | undefined) => void } {
  const router = useRouter()
  function submitSearch (value: string | undefined) {
    if (value) {
      const currentLocale = router.currentRoute.value.params.locale
      router.push({ name: 'search', params: { search: value, locale: currentLocale } })
    }
  }
  return { submitSearch }
}
