import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { RootProperties, SiteConfiguration, defaults } from '@olyslager/customer-configuration'
import { eventEmitter, siteConfigurationCache } from '@olyslager/global-utilities'

export type FrontendProperties = {
  showInstantSearch: boolean;
}

export const frontendPropertiesDefault: FrontendProperties = {
  showInstantSearch: true
}

export const useSiteConfigurationStore = defineStore('siteConfiguration', () => {
  const siteConfiguration: Ref<SiteConfiguration | undefined> = ref()
  const rootProperties: Ref<RootProperties> = ref(defaults.properties)
  const frontendProperties: Ref<FrontendProperties> = ref(frontendPropertiesDefault)

  const refreshConfiguration = () => {
    const updatedSiteConfiguration = siteConfigurationCache.get()
    siteConfiguration.value = updatedSiteConfiguration
    if (updatedSiteConfiguration) {
      rootProperties.value = { ...defaults.properties, ...updatedSiteConfiguration.properties }
      const frontend = updatedSiteConfiguration.frontends.find(f => f.name === APP_NAME)
      if (frontend) {
        frontendProperties.value = { ...frontendPropertiesDefault, ...frontend.properties }
      }
    }
  }

  eventEmitter.on('refreshFrontend', (event) => {
    if (event.frontendName === 'all' || event.frontendName === APP_NAME) {
      refreshConfiguration()
    }
  })
  refreshConfiguration()

  return { siteConfiguration, rootProperties, frontendProperties }
})
