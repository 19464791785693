<script lang="ts" setup>
import { ref } from 'vue'

import { OlyTextBox, translator } from '@olyslager/shared-components'

import useSubmitSearch from '@/composables/submitSearch'

const { t } = translator.useI18n()

const searchInput = ref<string>()
const { submitSearch } = useSubmitSearch()
const searchIcon = import.meta.env.VITE_NETLIFY_CDN_URL + '/global-icons/search.svg'

</script>

<template>
  <div>
    <OlyTextBox
      v-model="searchInput"
      :icon-url="searchIcon"
      :placeholder-text="t('searchinput.search-placeholder')"
      @submit="submitSearch"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
