import { Router } from 'vue-router'
import { I18n } from 'vue-i18n'

import { Translations } from '@olyslager/customer-configuration'
import { moaRouter } from '@olyslager/shared-components'

import TextSearch from '@/components/TextSearch.vue'

const createRouter = (i18n: I18n<Translations> | undefined): Router => {
  const router = moaRouter.createRouter(
    {
      debuggerProjectName: APP_NAME,
      rootPath: '/:locale',
      rootComponent: TextSearch,
      i18nInstance: i18n,
      routes: [
        {
          path: '',
          name: 'home',
          component: TextSearch
        },
        {
          path: '/:locale/search/:search',
          name: 'search',
          component: TextSearch
        },
        {
          path: '/:locale/recommendation/:id',
          name: 'recommendation',
          component: TextSearch
        }
      ]
    })

  return router
}
export default createRouter
