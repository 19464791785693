import { SearchHighlightKey, SearchResult } from '@/types'

export function getHighlightedText (result: SearchResult, key: SearchHighlightKey, element = 'b'): string {
  let text = result[key]

  const searchHighlight = result.searchHighlights.find(highlight => highlight.key === key)
  if (searchHighlight) {
    for (let index = 0; index < searchHighlight.values.length; index++) {
      const value = searchHighlight.values[index]

      const startIndex = value.startIndex + ((element.length + 2) * index) + ((element.length + 3) * index)
      const endIndex = value.endIndex + ((element.length + 2) * index) + ((element.length + 3) * index)

      const prefix = text.substring(0, startIndex)
      const suffix = text.substring(endIndex + 1)
      const toWrap = text.substring(startIndex, endIndex + 1)

      text = `${prefix}<${element}>${toWrap}</${element}>${suffix}`
    }
  }

  return text
}
