<script lang="ts" setup>
import { PropType } from 'vue'

import { OlyButtonV2, OlyIcon, translator } from '@olyslager/shared-components'

import { SearchResult } from '@/types'

import useSubmitSearch from '@/composables/submitSearch'

import InstantSearchResultRow from './InstantSearchResultRow.vue'

defineProps({
  results: {
    type: Object as PropType<SearchResult[]>,
    required: false,
    default: null
  },
  hasErrors: {
    type: Boolean,
    required: false,
    default: false
  },
  searchInput: {
    type: String,
    required: false,
    default: ''
  }
})

const { t } = translator.useI18n()

const { submitSearch } = useSubmitSearch()
const searchIcon = import.meta.env.VITE_NETLIFY_CDN_URL + '/global-icons/search.svg'

</script>

<template>
  <div
    class="instant-search-results"
  >
    <ul v-if="results && results.length > 0">
      <InstantSearchResultRow
        v-for="result in results"
        :key="result.vehicleId"
        :result="result"
      />
      <li class="instant-search-results__button-wrapper">
        <hr>
        <OlyButtonV2
          class="instant-search-results__button"
          @click="submitSearch(searchInput)"
        >
          <OlyIcon
            class="instant-search-results__button-icon"
            :icon-url="searchIcon"
            icon-color="#fff"
            :height="20"
            :width="20"
          />
          {{ t('searchresults.search-search') }}
        </OlyButtonV2>
      </li>
    </ul>
    <ul v-if="!results || results.length === 0 || hasErrors">
      <InstantSearchResultRow :error="t('searchresults.search-error')" />
    </ul>
  </div>
</template>

<style scoped lang="scss">
  .instant-search-results {
    --resultsBackgroundColor: var(--main-input-color-1);
    --resultsBorderColor: var(--main-input-color-3);
    --dividerColor: var(--main-input-color-3);
    position: absolute;
    width: 100%;
    z-index: 2;
    ul {
      background-color: var(--resultsBackgroundColor);
      border-radius: calc(5px);
      border: 1px solid var(--resultsBorderColor);
      padding: 10px 0;
      margin-top: 10px;
      list-style: none;
      li.instant-search-results__button-wrapper {
        text-align: center;
        padding-bottom: 4px;
        &:hover {
          background-color: inherit;
          text-decoration: none;
        }
        hr {
          height: 1px;
          background-color: var(--dividerColor);
          border: 0;
          margin: 8px 0 6px 0;
        }
        .instant-search-results__button {
          --background: var(--main-togglebutton-off-hover-background-color);
          --text: var(--main-togglebutton-off-hover-font-color);
          --active-background: var(--main-defaultbutton-background-color);
          --active-text: var(--main-defaultbutton-font-color);
          position: relative;
          width: auto;
          height: auto;
          padding: 10px 10px 10px 40px;
          .instant-search-results__button-icon {
            position: absolute;
            left: 14px;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
    }
  }
</style>
