<script lang="ts" setup>
import { computed } from 'vue'

import { OlyHoverContainer, OlySectionHeader, translator } from '@olyslager/shared-components'

import { useSiteConfigurationStore } from '@/stores/siteConfiguration'

import InstantSearch from './InstantSearch/InstantSearch.vue'
import ManualSearch from './ManualSearch/ManualSearch.vue'

const { t } = translator.useI18n()

const siteConfigurationStore = useSiteConfigurationStore()

const showInstantSearch = computed(() => siteConfigurationStore.frontendProperties.showInstantSearch)

</script>

<template>
  <div class="text-search">
    <OlyHoverContainer>
      <OlySectionHeader
        :title="t('header.search-title')"
        :bartext="t('header.search-bartext')"
      />
      <div class="text-search__wrapper">
        <InstantSearch v-if="showInstantSearch" />
        <ManualSearch v-else />
      </div>
    </OlyHoverContainer>
  </div>
</template>

<style lang="scss">
  @import '@olyslager/shared-components/scss';

  .text-search {
    padding: 10px;
    grid-column-start: 2;
    grid-column-end: 5;
    &__wrapper {
      padding: 20px;
    }
    @include medium() {
      padding: 20px;
    }
  }
</style>
