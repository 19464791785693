import { App, createApp, h } from 'vue'
import { createPinia } from 'pinia'
import singleSpaVue from 'single-spa-vue'

import { clickOutside, translator } from '@olyslager/shared-components'

import createRouter from './router'

import myApp from './App.vue'

// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    name: 'Text Search',
    render () {
      return h(myApp)
    }
  },
  handleInstance (app: App) {
    const i18n = translator.createI18n({
      debuggerProjectName: APP_NAME
    })
    app.use(i18n)
    app.use(createRouter(i18n))
    app.directive('clickOutside', clickOutside)
    app.use(createPinia())
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
